
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import type { ICurrentlyOpenReturnValue } from '../../shared/general/services/OpeningHoursService'
import { globalLabelAsString } from '../../shared/general/services/StoreService'

@Component({
  name: 'ContactElementIsOpen',
})

export default class ContactElementIsOpen extends Vue {
  @Prop({ required: true }) openingHours! : ICurrentlyOpenReturnValue

  private label = this.openingHours.opened ? globalLabelAsString('closes_at_label') : globalLabelAsString('opens_at_label')
}
