
import { Component, Prop } from 'nuxt-property-decorator'
import { FSXARichText } from 'fsxa-pattern-library'
import { RichTextElement } from 'fsxa-api'
import BaseAccordionElement from './BaseAccordionElement.vue'
import BaseAccordion from './BaseAccordion.vue'
import BaseOpeningHours from '../BaseOpeningHours.vue'
import { currentlyOpen } from '../../../shared/general/services/OpeningHoursService'
import BaseGridLayout from '../../layouts/BaseGridLayout.vue'
import ILocationOpeningHoursStructuredData from '../../../shared/ILocationOpeningHoursStructuredData'
import sanitizeJSON from '../../../shared/general/services/SanitizeJSONService'
import { IOpeningHoursAreas } from '../../../shared/fsxa/interfaces/IOpeningHoursAreas'
import { globalLabelAsString } from '../../../shared/general/services/StoreService'
import { IOpeningHoursAreaData } from '../../../shared/fsxa/interfaces/ILocationData'
import BaseList from '../BaseList.vue'
import { ILink } from '../../../shared/general/interfaces/ILink'
import getLinkObject from '../../../shared/fsxa/services/LinkService'
import ContactElementIsOpen from '../../contact/ContactElementIsOpen.vue'

@Component({
  name: 'BaseAccordionOpeningHours',
  components: {
    ContactElementIsOpen,
    BaseGridLayout,
    BaseOpeningHours,
    BaseAccordion,
    BaseAccordionElement,
    RichText: FSXARichText,
    BaseList,
    BaseHeadline: () => import('../BaseHeadline.vue'),
  },
})
export default class BaseAccordionOpeningHours extends BaseAccordion {
  @Prop() openingHoursAreas! : IOpeningHoursAreas[]

  @Prop({ required: true }) structuredData! : ILocationOpeningHoursStructuredData

  @Prop({ required: true }) getUrlByPageId! : Function

  private get structuredDataStr () : string {
    return sanitizeJSON(JSON.stringify(this.structuredData))
  }

  private get openingHoursHeadline () : string {
    return globalLabelAsString('location_opening_hours_headline')
  }

  private get openingHoursHintTextHeadline () : string {
    return globalLabelAsString('location_opening_hours_hint_text_headline')
  }

  private get showAccordion () : boolean {
    return Object.keys(this.openingHoursAreas).length > 1
  }

  private showHintTextHeadline (text ?: RichTextElement[]) : boolean {
    return !!text?.length
  }

  private openingHours (element : IOpeningHoursAreaData) {
    return currentlyOpen(element)
  }

  private getStDownloadList (listElement : IOpeningHoursAreaData) : ILink[] {
    return listElement.data?.st_download_list?.map((elem) => getLinkObject(elem, this.getUrlByPageId)) || []
  }
}
