
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IOpeningHoursAreaData } from '../../shared/fsxa/interfaces/ILocationData'
import { mapOpeningHours, sortOpeningHours } from '../../shared/general/services/OpeningHoursService'

@Component({
  name: 'BaseOpeningHours',
  components: { BaseHeadline: () => import('./BaseHeadline.vue') },
})
export default class BaseOpeningHours extends Vue {
  @Prop({ required: true }) openingHoursAreaData! : IOpeningHoursAreaData

  @Prop({ default: false }) showHeadline! : boolean

  private openingHours = sortOpeningHours(mapOpeningHours(this.openingHoursAreaData))

  private get headline () : string {
    return this.openingHoursAreaData.data?.st_headline?.trim() || ''
  }
}
