import { globalLabelAsString } from './StoreService'
import { formatJoinFromToDate, isInNowTimeRange, isNowBeforeTime } from './DateTimeService'
import { IOpeningHoursAreaData } from '../../fsxa/interfaces/ILocationData'
import capitalize from './Formatter'

// the keys in st_opening_hours are always english, so we can make use of them and always get the same behavior and just translate the labels
const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

/**
 * Shifts the elements of an array to the left by a specified number of positions.
 * Wraps around to the right side of the array like a carousel.
 *
 * @template T - The type of array elements. Most of the time auto inferred by the given array.
 * @param {T[]} arr - The array to be shifted.
 * @param {number} shiftBy - The number of positions to shift the elements.
 * @returns {T[]} - The shifted array.
 */
function shiftArrayLeft<T> (arr : T[], shiftBy : number) : T[] {
  if (shiftBy === 0) return [...arr]
  const { length } = arr
  // We take the mod to handle the cases where shiftBy is greater than array length
  const shift = shiftBy % length
  return arr.slice(shift).concat(arr.slice(0, shift))
}

interface IOpeningHourObject {
  from : string
  fromRaw ?: string
  to : string
  toRaw ?: string
  open : boolean
}

type IOpeningHourRecord = Record<string, IOpeningHourObject[] | string>

const getWeekDayFromOpeningHoursAreaData = (day : string, openingHours : IOpeningHoursAreaData) => {
  const weekday : IOpeningHourObject[] = []
  openingHours.data?.st_opening_hours?.forEach((openingHoursData) => {
    // Check if opening Hours are for weekday
    const weekdayData = openingHoursData.data?.st_weekdays?.some((dayData) => dayData.key === day)
    if (weekdayData) {
      weekday.push({
        from: formatJoinFromToDate({ from: openingHoursData.data?.st_from, dateOptions: { timeStyle: 'short' } }),
        fromRaw: openingHoursData.data?.st_from,
        to: formatJoinFromToDate({ from: openingHoursData.data?.st_to, dateOptions: { timeStyle: 'short' } }),
        toRaw: openingHoursData.data?.st_to,
        open: isInNowTimeRange(openingHoursData.data?.st_from, openingHoursData.data?.st_to),
      })
    }
  })

  return weekday
}

const sortOpeningHours = (openingHours : IOpeningHourRecord) => {
  // We shift the weekdays to the left to have the current day on top
  // getDay() starts with 0 for Sunday, so we map that as well
  const now : Date = new Date()
  const shiftBy = now.getDay() === 0 ? 6 : now.getDay() - 1
  const shiftedWeekDays = shiftArrayLeft(weekdays, shiftBy)

  return Object.entries(openingHours)
    .sort((a, b) => shiftedWeekDays.indexOf(a[0]) - shiftedWeekDays.indexOf(b[0]))
    .map(([key, value]) => ({ day: globalLabelAsString(`${key}_label`), value }))
}

const mapOpeningHours = (openingHours : IOpeningHoursAreaData) => weekdays.reduce((openingHoursResult, day) => {
  const weekday = getWeekDayFromOpeningHoursAreaData(day, openingHours)
  openingHoursResult[day] = weekday.length === 0 ? capitalize(globalLabelAsString('closed_label')) : weekday
  return openingHoursResult
}, {} as IOpeningHourRecord)

export interface ICurrentlyOpenReturnValue {
  color : string
  text : string
  opened : boolean
  time ?: string
}

const currentlyOpen = (element : IOpeningHoursAreaData) : ICurrentlyOpenReturnValue => {
  const now : Date = new Date()
  const weekday : string = weekdays[now.getDay() === 0 ? 6 : now.getDay() - 1]
  const openingHours = mapOpeningHours(element)
  const today = openingHours[weekday]

  const closedColor = 'text-red'
  const openedColor = 'text-darkgreen'
  const closedLabel = capitalize(globalLabelAsString('closed_label'))
  const openedLabel = capitalize(globalLabelAsString('opened_label'))

  // If today is not a string, there are opening hours
  if (typeof today !== 'string') {
    // Currently opened, tell when will be closed
    const currentTimeIndex = today.findIndex((hours) => isInNowTimeRange(hours.fromRaw, hours.toRaw))
    if (currentTimeIndex > -1) {
      return {
        color: openedColor,
        text: openedLabel,
        opened: true,
        time: today[currentTimeIndex].to,
      }
    }

    // Currently closed, but there might be another opening hour for today
    const beforeNextTimeIndex = today.findIndex((hours) => isNowBeforeTime(hours.fromRaw))
    if (beforeNextTimeIndex > -1) {
      return {
        color: closedColor,
        text: closedLabel,
        opened: false,
        time: today[beforeNextTimeIndex].from,
      }
    }
  }

  // If there are no opening times or none of the before is applied, it's just closed
  return {
    color: closedColor,
    opened: false,
    text: closedLabel,
  }
}

export {
  currentlyOpen,
  shiftArrayLeft,
  mapOpeningHours,
  sortOpeningHours,
}
