
import {
  Component, Emit, Prop, Vue,
} from 'nuxt-property-decorator'
import { ILink } from '../../shared/general/interfaces/ILink'
import { TLink } from '../../shared/general/types/TLink'

@Component({
  name: 'BaseList',
  components: { BaseLink: () => import('./BaseLink.vue') },
})
export default class BaseList extends Vue {
  @Prop() header ?: string

  @Prop({ default: () => [] }) items! : ILink[]

  @Prop({ default: 'link' }) type! : TLink

  private get showAsLinkList () : boolean {
    return this.type === 'linkList'
  }

  private get itemsWithLabel () : ILink[] {
    return this.items.filter((item) => !!item.label)
  }

  @Emit('click')
  private click () : boolean {
    return true
  }
}
